import * as React from 'react'
import { PageLayout } from '../components'

const NotFoundPage: React.FC = () => {
  return (
    <PageLayout>
      <h1>Nope, not here</h1>
    </PageLayout>
  )
}

export default NotFoundPage
